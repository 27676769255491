import React, {useEffect} from 'react';
import PageTitle from 'components/PageTitle';
import Button from 'components/Button';
import {useOutletContext} from 'react-router-dom';

function SpecialLesson() {
	const specialLessonCardData = [
		{
			title: '期間限定：notion特別講義',
			videoSrc: 'https://storage.googleapis.com/zeroplus-portal/notion-special-lesson.mp4',
			description: 'Notion公式アンバサダーによる、Notion特別講義のアーカイブ。基本解説とオリジナルテンプレートのカスタマイズを実施しており、学習や仕事の効率を向上させたい方は必見です。',
			materialDownloadUrl:"https://integrition.notion.site/2025-01-18-Notion-Workshop-17f5669763b08047bbe2e083ee77e4db?pvs=4"
		},
		{
			title: '期間限定：ディレ森による特別講義',
			videoSrc: 'https://storage.googleapis.com/zeroplus-portal/diremori-exchange-meeting.mp4',
			description:
				'ZeroPlusとディレクターの森のコラボ交流会で実施された、Web業界のプロによる特別講義です。デザイナー、エンジニア、フリーランスを目指している人にプロから伝えたいことが詰まっています！'
		},
		{
			title: 'Web制作ディレクション特別講義',
			videoSrc: 'https://storage.googleapis.com/zeroplus-portal/web-direction.mp4',
			description:
				'Webの世界で差をつける効果的なサイト戦略を深堀り。ディレクターとしてのキャリアを目指す方には絶対に欠かせない内容です。必見！'
		},
		{
			title: 'Web制作営業特別講義',
			videoSrc: 'https://storage.googleapis.com/zeroplus-portal/web-sales.mp4',
			description:
				'成功する営業の背後にあるクライアント獲得の秘訣を紹介。初心者から経験者まで、信頼を築く方法を学びましょう！'
		},
		{
			title: 'ライティング特別講義',
			videoSrc: 'https://storage.googleapis.com/zeroplus-portal/writing.mp4',
			description:
				'言葉の力でユーザーを引き込む、魅力的なコンテンツ作成術。ライターやマーケティング担当者がスキルアップするチャンス！'
		},
		{
			title: 'デザイン特別講義',
			videoSrc: 'https://storage.googleapis.com/zeroplus-portal/design.mp4',
			description:
				'デザインの心得から最新トレンドまでを探る。UI/UXデザインを学び、クリエイティブな未来を切り開く一歩として。'
		},
		{
			title: 'Web技術特別講義',
			videoSrc: 'https://storage.googleapis.com/zeroplus-portal/web-technology.mp4',
			description:
				'授業では習わないWebの技術を一気に習得。開発者やフロントエンド初心者も、この講義で次のステップへと進む準備が整います。'
		},
		{
			title: 'SEO特別講義',
			videoSrc: 'https://storage.googleapis.com/zeroplus-portal/seo.mp4',
			description:
				'検索エンジンでのトップ表示を目指すSEOの奥義。サイト訪問者数を増やし、ビジネスの成功への道を示します。'
		},
		{
			title: 'Sass特別講義①（基礎編）',
			videoSrc: 'https://storage.googleapis.com/zeroplus-portal/sass-part1.mp4',
			description:
				'スタイリングの効率化を実現するSassの基礎から。デザイナーや開発者がさらに生産性を高める方法を探る講義です。',
			materialDownloadUrl: 'https://storage.googleapis.com/zeroplus-portal/sass-lesson01_practice.zip'
		},
		{
			title: 'Sass特別講義②（実践編）',
			videoSrc: 'https://storage.googleapis.com/zeroplus-portal/sass-part2.mp4',
			description:
				'実際のプロジェクトでのSass利用方法を深掘り。中級者以上の方がさらなるスキルアップを目指すための実践的な内容満載！',
			materialDownloadUrl: 'https://storage.googleapis.com/zeroplus-portal/sass-lesson02_practice.zip'
		},
		{
			title: 'Git特別講義①',
			videoSrc: 'https://storage.googleapis.com/zeroplus-portal/git-part1.mp4',
			description: 'チームでの開発をスムーズにするGitの基礎知識。コードの管理と共有を、効率的に行う方法を学びます。'
		},
		{
			title: 'Git特別講義②',
			videoSrc: 'https://storage.googleapis.com/zeroplus-portal/git-part2.mp4',
			description:
				'GitHubを駆使した協力作業のテクニックを紹介。プロジェクトの効率アップを目指す開発者やリーダーのための必見講義！'
		}
	];
	const {cookies, setIsSpecialLessonTutorialModalOpen} = useOutletContext() as {
		cookies: {isSpecialClassTutorial: boolean};
		setIsSpecialLessonTutorialModalOpen: (isOpen: boolean) => void;
	};
	useEffect(() => {
		if (cookies.isSpecialClassTutorial === undefined) {
			setIsSpecialLessonTutorialModalOpen(true);
		}
	}, []);

	return (
		<div className='mx-4'>
			<PageTitle
				title='特別講義'
				overview='オンデマンド特別講義は、通常講義では学ばない、
あなたのスキルアップ材料が揃っています。ぜひご覧ください。'
			/>
			<div className=' grid grid-cols-2 sm:grid-cols-1 gap-10 place-items-center'>
				{specialLessonCardData.map((data, index) => {
					return (
						<SpecialLessonCard
							key={index}
							title={data.title}
							videoSrc={data.videoSrc}
							description={data.description}
							materialDownloadUrl={data.materialDownloadUrl}
						/>
					);
				})}
			</div>
		</div>
	);
}

export default SpecialLesson;

type SpecialLessonCardProps = {
	title: string;
	videoSrc: string;
	description: String;
	materialDownloadUrl?: string;
};

function SpecialLessonCard({title, videoSrc, description, materialDownloadUrl}: SpecialLessonCardProps) {
	const cartdTitleGradationBorderStyle = {
		borderLeft: '8px solid',
		borderImage: 'linear-gradient(90deg, #FFB723 0%, #FF9552 100%) 1'
	};

	return (
		<div className='card w-full max-w-[496px] h-full bg-base-100 shadow-md rounded-lg'>
			<figure>
				<video
					className='h-[279px] w-full object-cover'
					width={496}
					height={279}
					src={videoSrc}
					controls
					controlsList='nodownload'
				></video>
			</figure>
			<div className='card-body'>
				<h2 style={cartdTitleGradationBorderStyle} className='card-title pl-4 mb-6'>
					{title}
				</h2>
				<p className='mb-10'>{description}</p>
				{materialDownloadUrl && (
					<div className='flex justify-center'>
						<Button title='教材のダウンロードはこちら' to={materialDownloadUrl} isExternal />
					</div>
				)}
			</div>
		</div>
	);
}

import React, {useEffect, useState, useContext, CSSProperties} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {Link, Outlet, useNavigate, useLocation} from 'react-router-dom';
import LogoImg from 'assets/images/zeroplus_logo.png';
import SocialLogoutButton from 'components/SocialLogoutButton';
import icon_home from 'assets/images/menuIcon/icon_home.svg';
import icon_community from 'assets/images/menuIcon/icon_community.svg';
import icon_question_support from 'assets/images/menuIcon/icon_question_support.svg';
import icon_event from 'assets/images/menuIcon/icon_event.svg';
import icon_special_lesson from 'assets/images/menuIcon/icon_ondemand.svg';
import icon_carrer from 'assets/images/menuIcon/icon_carrer.svg';
import icon_party from 'assets/images/menuIcon/icon_party.svg';
import icon_calendar from 'assets/images/menuIcon/icon_calendar.svg';
import icon_privacy_policy from 'assets/images/menuIcon/icon_privacy_policy.svg';
import icon_instagram from 'assets/images/menuIcon/icon_instagram.svg';
import icon_x from 'assets/images/menuIcon/icon_x.svg';
import icon_note from 'assets/images/menuIcon/icon_note.svg';
import icon_lancers from 'assets/images/menuIcon/icon_lancers.svg';
import icon_project_support from 'assets/images/menuIcon/icon_project_support.svg';
import {MenuItem} from 'components/menuItem';
import slackIcon from 'assets/images/tutorial/slack-icon.svg';
import tutorialTopImage1 from 'assets/images/tutorial/tutorial_top-page_01.png';
import tutorialTopImage2 from 'assets/images/tutorial/tutorial_top-page_02.png';
import tutorialTopImage3 from 'assets/images/tutorial/tutorial_top-page_03.png';
import tutorialTopImage4 from 'assets/images/tutorial/tutorial_top-page_04.png';
import tutorialLessonImage1 from 'assets/images/tutorial/tutorial_class_01.png';
import tutorialLessonImage2 from 'assets/images/tutorial/tutorial_class_02.png';
import tutorialLessonImage3 from 'assets/images/tutorial/tutorial_class_03.png';
import tutorialLessonImage4 from 'assets/images/tutorial/tutorial_class_04.png';
import tutorialLessonImage5 from 'assets/images/tutorial/tutorial_class_05.png';
import questionSupportImage1 from 'assets/images/tutorial/tutorial_question-support_01.png';
import questionSupportImage2 from 'assets/images/tutorial/tutorial_question-support_02.png';
import questionSupportImage3 from 'assets/images/tutorial/tutorial_question-support_03.png';
import questionSupportImage4 from 'assets/images/tutorial/tutorial_question-support_04.png';
import studyClassTutorialImage1 from 'assets/images/tutorial/tutorial_study-class_01.png';
import studyClassTutorialImage2 from 'assets/images/tutorial/tutorial_study-class_02.png';
import studyClassTutorialImage3 from 'assets/images/tutorial/tutorial_study-class_03.png';
import specialClassTutorialImage1 from 'assets/images/tutorial/tutorial_special-class_01.png';
import specialClassTutorialImage2 from 'assets/images/tutorial/tutorial_special-class_02.png';
import careerSupportTutorialImage1 from 'assets/images/tutorial/tutorial_career-support_01.png';
import careerSupportTutorialImage2 from 'assets/images/tutorial/tutorial_career-support_02.png';
import careerSupportTutorialImage3 from 'assets/images/tutorial/tutorial_career-support_03.png';
import appLessonTutorialImage1 from 'assets/images/tutorial/tutorial_class_app_01.png';
import appLessonTutorialImage4 from 'assets/images/tutorial/tutorial_class_app_04.png';
import appQuestionSupportTutorialImage4 from 'assets/images/tutorial/tutorial_question-support_app_04.png';
import appStudyClassTutorialImage1 from 'assets/images/tutorial/tutorial_study-class_app_01.png';
import appStudyClassTutorialImage2 from 'assets/images/tutorial/tutorial_study-class_app_02.png';
import exclamationIcon from 'assets/images/exclamation.svg';
import {useCookies} from 'react-cookie';
import {AuthUserContext, AuthUserContextType} from 'providers/AuthUserProvider';
import {QRCodeSVG} from 'qrcode.react';
import Rectangle from 'assets/images/Rectangle-6335.png';
import eventAnnouncement from 'assets/images/tutorial/event_announcement-img.png';

const gradientButtonStyle = {
  backgroundImage: 'linear-gradient(to right, #FFB723 50%, #FF9552 100%)',
  color: '#FFFFFF',
  border: 'none',
  padding: 0,
  textTransform: 'none' as const,
  minWidth: '150px'
};

const qrCodeUrls = {
  Webデザイン: {
    '31a': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=1WJNBS&liff_id=1655946421-5VbzZnAj',
    '31b': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=1WJNBS&liff_id=1655946421-5VbzZnAj',
    '32a': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=1gpfDi&liff_id=1655946421-5VbzZnAj',
    '32b': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=xw8OUq&liff_id=1655946421-5VbzZnAj',
    '33a': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=17WCsN&liff_id=1655946421-5VbzZnAj',
    '33b': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=9gnqwY&liff_id=1655946421-5VbzZnAj',
    '34a': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=MO3izA&liff_id=1655946421-5VbzZnAj',
    '34b': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=wi8SlT&liff_id=1655946421-5VbzZnAj',
    '35a': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=D2G3Hr&liff_id=1655946421-5VbzZnAj',
    '35b': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=z6abzG&liff_id=1655946421-5VbzZnAj',
    '36a': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=LtR4Sz&liff_id=1655946421-5VbzZnAj',
    '36b': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=drXPsZ&liff_id=1655946421-5VbzZnAj',
    '37a': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=VjEXQg&liff_id=1655946421-5VbzZnAj',
    '37b': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=6399ry&liff_id=1655946421-5VbzZnAj',
    '38a': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=CQK2F9&liff_id=1655946421-5VbzZnAj',
    '38b': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=rhWhxc&liff_id=1655946421-5VbzZnAj',
    '39a': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=iSvQgX&liff_id=1655946421-5VbzZnAj',
    '39b': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=wsQKdL&liff_id=1655946421-5VbzZnAj',
    '40a': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=7zSAJn&liff_id=1655946421-5VbzZnAj',
    '40b': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=o4lAEU&liff_id=1655946421-5VbzZnAj',
    '41a': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=yJxg5E&liff_id=1655946421-5VbzZnAj',
    '41b': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=msBN0c&liff_id=1655946421-5VbzZnAj',
    '42a': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=GYX7kR&liff_id=1655946421-5VbzZnAj',
    '42b': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=62gBLy&liff_id=1655946421-5VbzZnAj',

    '43a': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=CLvMCq&liff_id=1655946421-5VbzZnAj',
    '43b': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=dqLubr&liff_id=1655946421-5VbzZnAj',
    '44a': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=Ry7Wdr&liff_id=1655946421-5VbzZnAj',
    '44b': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=o8B2VF&liff_id=1655946421-5VbzZnAj',
    '45a': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=MW3umD&liff_id=1655946421-5VbzZnAj',
    '45b': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=wwXwup&liff_id=1655946421-5VbzZnAj',
    '46a': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=auAzDv&liff_id=1655946421-5VbzZnAj',
    '46b': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=6JueG6&liff_id=1655946421-5VbzZnAj',
    '47a': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=2P06Gn&liff_id=1655946421-5VbzZnAj',
    '47b': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=elspYe&liff_id=1655946421-5VbzZnAj',
    '48a': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=t7menJ&liff_id=1655946421-5VbzZnAj',
    '48b': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=6Zk6iO&liff_id=1655946421-5VbzZnAj',
    '49a': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=pNHw6r&liff_id=1655946421-5VbzZnAj',
    '49b': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=zLu1GM&liff_id=1655946421-5VbzZnAj',
    '50a': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=uHaeJR&liff_id=1655946421-5VbzZnAj',
    '50b': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=vmALyr&liff_id=1655946421-5VbzZnAj',
    '51a': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=v0kbZn&liff_id=1655946421-5VbzZnAj',
    '51b': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=zlqfUb&liff_id=1655946421-5VbzZnAj',
    '52a': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=0DDPoO&liff_id=1655946421-5VbzZnAj',
    '52b': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=DsXZte&liff_id=1655946421-5VbzZnAj',
    '53a': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=BVpbiY&liff_id=1655946421-5VbzZnAj',
    '53b': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=PuhR9H&liff_id=1655946421-5VbzZnAj',
    '54a': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=CJFK4e&liff_id=1655946421-5VbzZnAj',
    '54b': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=kvvGf3&liff_id=1655946421-5VbzZnAj',

    少人数: 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=tm530N&liff_id=1655946421-5VbzZnAj'
  },
  Webアプリケーション: {
    '1': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=6qhejh&liff_id=1655946421-5VbzZnAj',
    '2': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=k1YRfu&liff_id=1655946421-5VbzZnAj',
    '3': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=8iksQ5&liff_id=1655946421-5VbzZnAj',
    '4': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=OaRLSW&liff_id=1655946421-5VbzZnAj',
    '5': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=E8DfHz&liff_id=1655946421-5VbzZnAj',
    '6': 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=KplgHf&liff_id=1655946421-5VbzZnAj'
  }
} as {[key: string]: {[key: string]: string}};

function MainLayout() {
  // TODO: 詳細情報取得の処理
  // TODO: 詳細情報登録してない場合の処理
  const [isLogoutModal, setIsLogoutModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {user} = useAuth0();
  const navigate = useNavigate();
  const [isFirstTutorialModalOpen, setIsFirstTutorialModalOpen] = useState<boolean>(false);
  const [isTopTutorialModalOpen, setIsTopTutorialModalOpen] = useState<boolean>(false);
  const [isLessonTutorialModalOpen, setIsLessonTutorialModalOpen] = useState<boolean>(false);
  const [isQuestionSupportTutorialModalOpen, setIsQuestionSupportTutorialModalOpen] = useState<boolean>(false);
  const [isStudyClassTutorialModalOpen, setIsStudyClassTutorialModalOpen] = useState<boolean>(false);
  const [isSpecialLessonTutorialModalOpen, setIsSpecialLessonTutorialModalOpen] = useState<boolean>(false);
  const [isCareerSupportTutorialModalOpen, setIsCareerSupportTutorialModalOpen] = useState<boolean>(false);

  const authUserContext = useContext<AuthUserContextType>(AuthUserContext);
  const studentCourseName = authUserContext.userContractCourseName;
  const zeroplusId = authUserContext.user?.zeroplus_id;
  const sanitizedZeroplusId = zeroplusId?.replace(/-/g, '');
  // NOTE: QRコードを出し分けるために少人数の場合は少人数という文字列に変換
  const studentClassroomDisplayName = authUserContext.studentClassroomData[0]?.classroom_display_name.includes('少人数')
    ? '少人数'
    : authUserContext.studentClassroomData[0]?.classroom_display_name;

  const [cookies, setCookie] = useCookies([
    'isTopTutorial',
    'isLessonTutorial',
    'isQuestionSupportTutorial',
    'isStudyClassTutorial',
    'isSpecialClassTutorial',
    'isCareerSupportTutorial'
  ]);

  const path = useLocation().pathname;

  const handlePathChange = () => {
    switch (path) {
      case '/':
        setIsTopTutorialModalOpen(true);
        break;
      case '/lesson':
        setIsLessonTutorialModalOpen(true);
        break;
      case '/question-support':
        setIsQuestionSupportTutorialModalOpen(true);
        break;
      case '/study-class':
        setIsStudyClassTutorialModalOpen(true);
        break;
      case '/special-lesson':
        setIsSpecialLessonTutorialModalOpen(true);
        break;
      case '/career':
        setIsCareerSupportTutorialModalOpen(true);
        break;
      default:
        break;
    }
  };

  const addIsTopTutorialCookie = () => {
    setCookie('isTopTutorial', true, {path: '/', maxAge: 60 * 60 * 24 * 365});
  };

  const addIsLessonTutorialCookie = () => {
    setCookie('isLessonTutorial', true, {path: '/', maxAge: 60 * 60 * 24 * 365});
  };

  const addIsQuestionSupportTutorialCookie = () => {
    setCookie('isQuestionSupportTutorial', true, {path: '/', maxAge: 60 * 60 * 24 * 365});
  };

  const addIsStudyClassTutorialCookie = () => {
    setCookie('isStudyClassTutorial', true, {path: '/', maxAge: 60 * 60 * 24 * 365});
  };

  const addIsSpecialClassTutorialCookie = () => {
    setCookie('isSpecialClassTutorial', true, {path: '/', maxAge: 60 * 60 * 24 * 365});
  };

  const addIsCareerSupportTutorialCookie = () => {
    setCookie('isCareerSupportTutorial', true, {path: '/', maxAge: 60 * 60 * 24 * 365});
  };

  useEffect(() => {
    if (user?.['https://zero-plus.io/user_metadata'].zeroplus_id === undefined) {
      navigate('/first-access');
    }
  }, [user]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenMenu = () => {
    setIsMenuOpen(true);
  };
  const onCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const handleLogoutModal = () => {
    setIsLogoutModal(!isLogoutModal);
  };

  const webDesignFirstTutorialModalContent: tutorialModalContent = [
    {
      title: 'Slackの登録',
      exclamation: '担当者の案内に従って完了させましょう。',
      exclamationIcon: exclamationIcon,
      btnText: '登録はこちら',
      btnUrl:
        studentCourseName === 'Webデザイン'
          ? 'https://join.slack.com/t/zero-plushq/shared_invite/zt-25ilhmx4z-_CZSvyvMV_mW0wAUbexZFQ'
          : studentCourseName === 'Webアプリケーション'
          ? 'https://join.slack.com/t/zeroplusweb/shared_invite/zt-2792a9sv5-B4KKOS_DpL0idt0tCApm1A'
          : undefined,
      contentImage: slackIcon
    },
    {
      title: '公式LINEの登録',
      exclamation: '担当者の案内に従って完了させましょう。',
      exclamationIcon: exclamationIcon,
      contentImage: (
        <QRCodeSVG
          value={
            studentCourseName && qrCodeUrls[studentCourseName][studentClassroomDisplayName] // FIXME: QRコードの出し分けをもっとうまくやる
              ? qrCodeUrls[studentCourseName][studentClassroomDisplayName]
              : 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=IX5Qj5&liff_id=1655946421-5VbzZnAj'
          }
          size={160}
          bgColor={'#ffffff'}
          fgColor={'#000000'}
          level={'L'}
          includeMargin={false}
        />
      ),
      btnText: 'スマホの方はこちら',
      btnUrl:
        studentCourseName && qrCodeUrls[studentCourseName][studentClassroomDisplayName] // FIXME: QRコードの出し分けをもっとうまくやる
          ? qrCodeUrls[studentCourseName][studentClassroomDisplayName]
          : 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=IX5Qj5&liff_id=1655946421-5VbzZnAj'
    },
    {
      slideImage: tutorialTopImage1
    },
    {
      slideImage: tutorialTopImage2
    },
    {
      slideImage: tutorialTopImage3
    },
    {
      slideImage: tutorialTopImage4
    },
    {
      slideImage: studyClassTutorialImage1
    },
    {
      slideImage: studyClassTutorialImage2
    },
    {
      slideImage: studyClassTutorialImage3
    },
    {
      title: '入会アンケート回答のお願い',
      exclamation: 'ZeroPlusご入会時のアンケートにご協力お願いいたします！',
      exclamationIcon: exclamationIcon,
      btnText: 'Google Formへ',
      btnUrl: `https://docs.google.com/forms/d/1SncICYrZvQQVfstTowrGOnEhQVgCSON_WLs9db3pTxg/viewform?usp=pp_url&entry.1480875519=${sanitizedZeroplusId}`,
      btmStyle: gradientButtonStyle,
      contentImage: Rectangle,
      isComplete: true
    }
  ];

  const webDesignTopTutorialModalContent: tutorialModalContent = [
    {
      slideImage: tutorialTopImage1
    },
    {
      slideImage: tutorialTopImage2
    },
    {
      slideImage: tutorialTopImage3
    },
    {
      slideImage: tutorialTopImage4
    },
    {
      slideImage: studyClassTutorialImage1
    },
    {
      slideImage: studyClassTutorialImage2
    },
    {
      slideImage: studyClassTutorialImage3
    }
  ];
  const webAppFirstTutorialModalContent: tutorialModalContent = [
    {
      title: 'Slackの登録',
      exclamation: '担当者の案内に従って完了させましょう。',
      exclamationIcon: exclamationIcon,
      btnText: '登録はこちら',
      btnUrl:
        studentCourseName === 'Webデザイン'
          ? 'https://join.slack.com/t/zero-plushq/shared_invite/zt-25ilhmx4z-_CZSvyvMV_mW0wAUbexZFQ'
          : studentCourseName === 'Webアプリケーション'
          ? 'https://join.slack.com/t/zeroplusweb/shared_invite/zt-2792a9sv5-B4KKOS_DpL0idt0tCApm1A'
          : undefined,
      contentImage: slackIcon
    },
    {
      title: '公式LINEの登録',
      exclamation: '担当者の案内に従って完了させましょう。',
      exclamationIcon: exclamationIcon,
      contentImage: (
        <QRCodeSVG
          value={
            studentCourseName && qrCodeUrls[studentCourseName][studentClassroomDisplayName] // FIXME: QRコードの出し分けをもっとうまくやる
              ? qrCodeUrls[studentCourseName][studentClassroomDisplayName]
              : 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=IX5Qj5&liff_id=1655946421-5VbzZnAj'
          }
          size={160}
          bgColor={'#ffffff'}
          fgColor={'#000000'}
          level={'L'}
          includeMargin={false}
        />
      ),
      btnText: '登録はこちら',
      btnUrl:
        studentCourseName && qrCodeUrls[studentCourseName][studentClassroomDisplayName] // FIXME: QRコードの出し分けをもっとうまくやる
          ? qrCodeUrls[studentCourseName][studentClassroomDisplayName]
          : 'https://liff.line.me/1655946421-5VbzZnAj/landing?follow=%40078mshyn&lp=IX5Qj5&liff_id=1655946421-5VbzZnAj'
    },
    {
      slideImage: tutorialTopImage1
    },
    {
      slideImage: tutorialTopImage2
    },
    {
      slideImage: tutorialTopImage3
    },
    {
      slideImage: tutorialTopImage4
    },
    {
      slideImage: appStudyClassTutorialImage1
    },
    {
      slideImage: appStudyClassTutorialImage2
    },
    {
      title: '入会アンケート回答のお願い',
      exclamation: 'ZeroPlusご入会時のアンケートにご協力お願いいたします！',
      exclamationIcon: exclamationIcon,
      btnText: 'Google Formへ',
      btnUrl: `https://docs.google.com/forms/d/1SncICYrZvQQVfstTowrGOnEhQVgCSON_WLs9db3pTxg/viewform?usp=pp_url&entry.1480875519=${sanitizedZeroplusId}`,
      btmStyle: gradientButtonStyle,
      contentImage: Rectangle,
      isComplete: true
    }
  ];

  const webAppTopTutorialModalContent: tutorialModalContent = [
    {
      slideImage: tutorialTopImage1
    },
    {
      slideImage: tutorialTopImage2
    },
    {
      slideImage: tutorialTopImage3
    },
    {
      slideImage: tutorialTopImage4
    },
    {
      slideImage: appStudyClassTutorialImage1
    },
    {
      slideImage: appStudyClassTutorialImage2
    }
  ];

  const webDesignLessonTutorialModalContent: tutorialModalContent = [
    {
      slideImage: tutorialLessonImage1
    },
    {
      slideImage: tutorialLessonImage2
    },
    {
      slideImage: tutorialLessonImage3
    },
    {
      slideImage: tutorialLessonImage4
    },
    {
      slideImage: tutorialLessonImage5
    }
  ];
  const webAppLessonTutorialModalContent: tutorialModalContent = [
    {
      slideImage: appLessonTutorialImage1
    },
    {
      slideImage: tutorialLessonImage2
    },
    {
      slideImage: tutorialLessonImage3
    },
    {
      slideImage: appLessonTutorialImage4
    },
    {
      slideImage: tutorialLessonImage5
    }
  ];

  const webDesignQuestionSupportTutorialModalContent: tutorialModalContent = [
    {
      slideImage: questionSupportImage1
    },
    {
      slideImage: questionSupportImage2
    },
    {
      slideImage: questionSupportImage3
    },
    {
      slideImage: questionSupportImage4
    }
  ];
  const webAppQuestionSupportTutorialModalContent: tutorialModalContent = [
    {
      slideImage: questionSupportImage1
    },
    {
      slideImage: questionSupportImage2
    },
    {
      slideImage: questionSupportImage3
    },
    {
      slideImage: appQuestionSupportTutorialImage4
    }
  ];

  const careerSupportTutorialModalContent: tutorialModalContent = [
    {
      slideImage: careerSupportTutorialImage1
    },
    {
      slideImage: careerSupportTutorialImage2
    },
    {
      slideImage: careerSupportTutorialImage3
    }
  ];

  const specialClassTutorialModalContent: tutorialModalContent = [
    {
      slideImage: specialClassTutorialImage1
    },
    {
      slideImage: specialClassTutorialImage2
    }
  ];
  return (
    <div className='overflow-hidden'>
      <header
        className='navbar justify-between bg-white transition-all top-0 fixed px-8 z-10 h-[77px] 
        '
      >
        <div className=''>
          <Link to='/'>
            <img className='w-28 mx-auto' src={LogoImg} alt='ZeroPlus Portal logo' />
          </Link>
        </div>

        <div className='dropdown dropdown-end'>
          <label tabIndex={0} className='btn btn-ghost btn-circle avatar placeholder'>
            <div className='text-neutral-content rounded-full w-10'>
              <img src={user?.picture} alt='' />
            </div>
          </label>
          <div tabIndex={0} className='dropdown-content shadow menu bg-base-100 w-52 rounded-box mt-3'>
            <div className='border-b-[1px] py-3 px-4'>
              <span className='flex items-center gap-4'>
                <div className='text-neutral-content w-10'>
                  <img className='rounded-full' src={user?.picture} />
                </div>

                <p>{user?.name}</p>
                {/* TODO: 名前表示 */}
              </span>
            </div>
            <ul>
              <li>
                <button onClick={handleLogoutModal}>ログアウト</button>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <main className='h-[calc(100vh)] pt-[77px] w-full'>
        <div className='drawer drawer-mobile relative'>
          <input id='hamburger' type='checkbox' className='drawer-toggle' checked={isMenuOpen} readOnly />
          <button
            onClick={toggleMenu}
            className='w-12 h-12 z-30 fixed invisible lg:visible bottom-[88px] right-4 rounded-full btn btn-circle swap swap-rotate'
          >
            <input type='checkbox' checked={isMenuOpen} readOnly />
            <svg
              className='swap-off fill-current'
              xmlns='http://www.w3.org/2000/svg'
              width='32'
              height='32'
              viewBox='0 0 512 512'
            >
              <path d='M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z' />
            </svg>

            <svg
              className='swap-on fill-current'
              xmlns='http://www.w3.org/2000/svg'
              width='32'
              height='32'
              viewBox='0 0 512 512'
            >
              <polygon points='400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49' />
            </svg>
          </button>
          <div
            className={`drawer-content pb-20 bg-[#f8f8f8] ${isMenuOpen ? 'ml-[320px] lg:ml-0' : 'ml-[96px] lg:ml-0'}`}
          >
            <div className={`transition-all py-[120px] overflow-y-auto duration-300 mx-auto max-w-[1080px]`}>
              <Outlet
                context={{
                  cookies: cookies,
                  setIsFirstTutorialModalOpen: setIsFirstTutorialModalOpen,
                  setIsLessonTutorialModalOpen: setIsLessonTutorialModalOpen,
                  setIsQuestionSupportTutorialModalOpen: setIsQuestionSupportTutorialModalOpen,
                  setIsSpecialLessonTutorialModalOpen: setIsSpecialLessonTutorialModalOpen,
                  setIsCareerSupportTutorialModalOpen: setIsCareerSupportTutorialModalOpen
                }}
              />
            </div>
            <footer className='bg-white h-16 text-center flex justify-center items-center'>
              <p className='font-bold'>© ZeroPlus</p>
            </footer>
          </div>

          <div className={`drawer-side fixed h-full`}>
            {/*  */}
            <label onClick={toggleMenu} className='drawer-overlay'></label>
            <ul
              className={`menu pt-6 h-full bg-base-100 overflow-y-auto overscroll-contain pb-[100px] flex-nowrap text-base-content ${
                isMenuOpen ? 'w-[320px] lg:w-screen' : 'w-24 md:w-0'
              }`}
              onMouseEnter={onOpenMenu}
              onMouseLeave={onCloseMenu}
              onClick={onCloseMenu}
            >
              <MenuItem menuTitle={'トップ'} icon={icon_home} link='/' isMenuOpen={isMenuOpen} />
              <MenuItem
                menuTitle={'質問サポート'}
                icon={icon_question_support}
                link='/question-support'
                isMenuOpen={isMenuOpen}
              />
              <MenuItem
                menuTitle={'特別講義'}
                icon={icon_special_lesson}
                link='/special-lesson'
                isMenuOpen={isMenuOpen}
              />
              <MenuItem menuTitle={'いつでもZeroPlus'} icon={icon_community} link='/community' isMenuOpen={isMenuOpen} />
              {studentCourseName === 'Webデザイン' ? (
                <>
                  <MenuItem
                    menuTitle={'スクールカレンダー'}
                    icon={icon_calendar}
                    link='https://zero-plus.io/calendar/'
                    isMenuOpen={isMenuOpen}
                    isExternal
                  />
                  <MenuItem
                    menuTitle={'案件1on1サポート'}
                    icon={icon_project_support}
                    link='/project-support'
                    isMenuOpen={isMenuOpen}
                  />
                </>
              ) : null}
              <MenuItem menuTitle={'キャリア相談'} icon={icon_carrer} link='/career' isMenuOpen={isMenuOpen} />
              <div className='w-full transition-all py-6 flex justify-center items-center'>
                <div className={`bg-gray-200 h-[1px] ${isMenuOpen ? 'w-[248px]' : 'w-10'}`}></div>
              </div>
              <MenuItem
                menuTitle={'ZeroPlus 公式アカウント'}
                icon={icon_x}
                link='https://twitter.com/ZeroPlus_Japan'
                isMenuOpen={isMenuOpen}
                isExternal
              />
              <MenuItem
                menuTitle={'ZeroPlus コミュニティ'}
                icon={icon_x}
                link='https://twitter.com/i/communities/1744908823708746176'
                isMenuOpen={isMenuOpen}
                isExternal
              />
              <div className='w-full transition-all py-6 flex justify-center items-center'>
                <div className={`bg-gray-200 h-[1px] ${isMenuOpen ? 'w-[248px]' : 'w-10'}`}></div>
              </div>
              <MenuItem
                menuTitle='プライバシーポリシー'
                icon={icon_privacy_policy}
                link='/privacy-policy'
                isMenuOpen={isMenuOpen}
              />
            </ul>
          </div>
        </div>
        {isLogoutModal && <LogoutModal isLogoutModal={isLogoutModal} handleLogoutModal={handleLogoutModal} />}

        {isFirstTutorialModalOpen && (
          <TutrialModal
            isTutorialModalOpen={isFirstTutorialModalOpen}
            setIsTutorialModalOpen={setIsFirstTutorialModalOpen}
            tutorialModalContent={
              studentCourseName === 'Webデザイン' ? webDesignFirstTutorialModalContent : webAppFirstTutorialModalContent
            }
            addIsTutorialCookie={addIsTopTutorialCookie}
          />
        )}
        {isTopTutorialModalOpen && (
          <TutrialModal
            isTutorialModalOpen={isTopTutorialModalOpen}
            setIsTutorialModalOpen={setIsTopTutorialModalOpen}
            tutorialModalContent={
              studentCourseName === 'Webデザイン' ? webDesignTopTutorialModalContent : webAppTopTutorialModalContent
            }
            addIsTutorialCookie={addIsTopTutorialCookie}
          />
        )}
        {isLessonTutorialModalOpen && (
          <TutrialModal
            isTutorialModalOpen={isLessonTutorialModalOpen}
            setIsTutorialModalOpen={setIsLessonTutorialModalOpen}
            tutorialModalContent={
              studentCourseName === 'Webデザイン'
                ? webDesignLessonTutorialModalContent
                : webAppLessonTutorialModalContent
            }
            addIsTutorialCookie={addIsLessonTutorialCookie}
          />
        )}
        {isQuestionSupportTutorialModalOpen && (
          <TutrialModal
            isTutorialModalOpen={isQuestionSupportTutorialModalOpen}
            setIsTutorialModalOpen={setIsQuestionSupportTutorialModalOpen}
            tutorialModalContent={
              studentCourseName === 'Webデザイン'
                ? webDesignQuestionSupportTutorialModalContent
                : webAppQuestionSupportTutorialModalContent
            }
            addIsTutorialCookie={addIsQuestionSupportTutorialCookie}
          />
        )}
        {isSpecialLessonTutorialModalOpen && (
          <TutrialModal
            isTutorialModalOpen={isSpecialLessonTutorialModalOpen}
            setIsTutorialModalOpen={setIsSpecialLessonTutorialModalOpen}
            tutorialModalContent={specialClassTutorialModalContent}
            addIsTutorialCookie={addIsSpecialClassTutorialCookie}
          />
        )}
        {isCareerSupportTutorialModalOpen && (
          <TutrialModal
            isTutorialModalOpen={isCareerSupportTutorialModalOpen}
            setIsTutorialModalOpen={setIsCareerSupportTutorialModalOpen}
            tutorialModalContent={careerSupportTutorialModalContent}
            addIsTutorialCookie={addIsCareerSupportTutorialCookie}
          />
        )}
        {path === '/' ||
        path === '/lesson' ||
        path === '/question-support' ||
        path === '/study-class' ||
        path === '/special-lesson' ||
        path === '/career' ? (
          <button
            className='fixed bottom-6 right-4 w-12 h-12 bg-white border-none rounded-full shadow-lg font-bold text-[22px]'
            onClick={handlePathChange}
          >
            ?
          </button>
        ) : null}
      </main>
    </div>
  );
}

type modalProps = {
  isLogoutModal: boolean;
  handleLogoutModal: () => void;
};

type tutorialModalContent = {
  title?: string;
  exclamation?: string;
  exclamationIcon?: string;
  contentImage?: string | React.ReactNode;
  eventContentImage?: string;
  btnText?: string;
  btnUrl?: string;
  btmStyle?: CSSProperties | undefined;
  slideImage?: string;
  isComplete?: boolean;
}[];

type tutorialModalProps = {
  isTutorialModalOpen: boolean;
  setIsTutorialModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tutorialModalContent: tutorialModalContent;
  addIsTutorialCookie: () => void;
};

export function LogoutModal(props: modalProps) {
  return (
    <div className={`modal ${props.isLogoutModal && 'modal-open'}`}>
      <div className='modal-box py-14 flex flex-col gap-10 max-w-[720px]'>
        <p className=' text-2xl text-center font-bold'>本当にログアウトしますか？</p>
        <p className='text-center'>
          やり残したことはございませんか？ <br /> ログアウトボタンをクリックするとログアウトします。
        </p>
        <div className='flex lg:flex-col flex-row justify-center items-center gap-10'>
          <button className='btn w-[200px] block lg:mx-auto mx-0' onClick={props.handleLogoutModal}>
            戻る
          </button>
          <SocialLogoutButton />
        </div>
      </div>
    </div>
  );
}

function TutrialModal(props: tutorialModalProps) {
  const [tutorialModalNumber, setTutorialModalNumber] = useState(0);
  const maxModalNumber = props.tutorialModalContent.length;
  const [isComplete, setIsComplete] = useState(false);

  const handleIsComplete = () => {
    setIsComplete(!isComplete);
  };

  const handleNextTutorialModal = () => {
    const nextModalNumber = tutorialModalNumber + 1;

    if (nextModalNumber === maxModalNumber) {
      props.setIsTutorialModalOpen(false);
      props.addIsTutorialCookie();
    } else {
      setTutorialModalNumber(nextModalNumber);
    }
  };
  const handlePrevTutorialModal = () => {
    const nextModalNumber = tutorialModalNumber - 1;

    if (nextModalNumber === -1) {
      props.setIsTutorialModalOpen(false);
    } else {
      setTutorialModalNumber(nextModalNumber);
    }
  };
  return (
    <div className={`modal ${props.isTutorialModalOpen && 'modal-open'}`}>
      <div className='modal-box py-14 sm:py-5 flex flex-col gap-10 px-5 sm:px-0 max-w-[840px]'>
        {props.tutorialModalContent[tutorialModalNumber].slideImage && (
          <img width={1600} height={900} src={props.tutorialModalContent[tutorialModalNumber].slideImage} alt='' />
        )}
        {props.tutorialModalContent[tutorialModalNumber].title && (
          <div className='w-[800px] h-[420px] lg:w-full bg-[#FAFAFA] text-center'>
            <div className='mt-4'>
              {props.tutorialModalContent[tutorialModalNumber].title && (
                <p className=' text-[22px] font-bold'>{props.tutorialModalContent[tutorialModalNumber].title}</p>
              )}
              <div className='flex justify-center mt-2'>
                {props.tutorialModalContent[tutorialModalNumber].exclamationIcon && (
                  <img
                    className='sm:hidden'
                    width={20}
                    height={20}
                    src={props.tutorialModalContent[tutorialModalNumber].exclamationIcon}
                    alt=''
                  />
                )}
                {props.tutorialModalContent[tutorialModalNumber].exclamation && (
                  <p className='ml-2'>{props.tutorialModalContent[tutorialModalNumber].exclamation}</p>
                )}
              </div>
            </div>
            <div className='flex justify-center mt-10'>
              {props.tutorialModalContent[tutorialModalNumber].contentImage &&
              typeof props.tutorialModalContent[tutorialModalNumber].contentImage === 'string' ? (
                <img
                  src={props.tutorialModalContent[tutorialModalNumber].contentImage as string}
                  alt=''
                  width={100}
                  height={100}
                />
              ) : (
                props.tutorialModalContent[tutorialModalNumber].contentImage
              )}
              {props.tutorialModalContent[tutorialModalNumber].eventContentImage && (
                <img
                  src={props.tutorialModalContent[tutorialModalNumber].eventContentImage}
                  alt=''
                  width={250}
                  height={250}
                />
              )}
            </div>
            {props.tutorialModalContent[tutorialModalNumber].btnText &&
              props.tutorialModalContent[tutorialModalNumber].btnUrl && (
                <Link
                  to={props.tutorialModalContent[tutorialModalNumber].btnUrl!}
                  style={props.tutorialModalContent[tutorialModalNumber].btmStyle}
                  className='btn bg-[#361137] mt-6'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {props.tutorialModalContent[tutorialModalNumber].btnText}
                </Link>
              )}
            {props.tutorialModalContent[tutorialModalNumber].isComplete && (
              <div className='form-control mt-20 sm:mt-10'>
                <label className='label cursor-pointer justify-center gap-2' onChange={handleIsComplete}>
                  <input type='checkbox' checked={isComplete} className='checkbox checkbox-primary' />
                  <span className='label-text'>入会アンケートに回答しました</span>
                </label>
              </div>
              // <div className='flex justify-center mt-20 sm:mt-10 sm:mb-4 gap-1' onClick={handleIsComplete}>
              //  <input type='checkbox' checked={isComplete} className='checkbox' />
              //  <p>入会アンケートに回答しました</p>
              // </div>
            )}
          </div>
        )}

        <div className='flex lg:flex-col flex-row justify-between items-center gap-4'>
          <p className='lg:text-center text-right hidden sm:block'>
            {tutorialModalNumber + 1} / {maxModalNumber}
          </p>
          <button
            {...(tutorialModalNumber === 0 && {disabled: true})}
            className='btn w-[200px] block lg:mx-auto mx-0'
            onClick={handlePrevTutorialModal}
          >
            戻る
          </button>
          <p className='lg:text-center text-right sm:hidden'>
            {tutorialModalNumber + 1} / {maxModalNumber}
          </p>

          <button
            {...(props.tutorialModalContent[tutorialModalNumber].isComplete && !isComplete && {disabled: true})}
            style={gradientButtonStyle}
            className={`btn w-[200px] block lg:mx-auto mx-0 ${
              props.tutorialModalContent[tutorialModalNumber].isComplete && !isComplete && 'opacity-50'
            }`}
            onClick={handleNextTutorialModal}
          >
            {tutorialModalNumber === maxModalNumber - 1 ? '完了' : '次へ'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default MainLayout;


import React from 'react';
import PageTitle from 'components/PageTitle';
import Button from 'components/Button';
import CommunityDiscordImg from 'assets/images/community_illustration.png';

function Community() {
	return (
		<div className='mx-4'>
			{/* TODO: props.overviewをどのようにして改行できるようにするか考えて、修正する */}
			<PageTitle
				title='いつでもZeroPlus'
				overview='ZeroPlusの生徒さん同士でコミュニケーションを取りましょう！
        仲間と一緒に息抜きをしたり、勉強のヒントをもらえたり…時には案件がもらえるかも？'
			/>

			<div className='py-10 px-4 mb-20 bg-white rounded-lg'>
				<div className='w-full max-w-[960px] m-auto'>
					<h3 className='text-2xl font-bold mb-8 text-center'>いつでもZeroPlusって？</h3>
					<div className='flex lg:flex-col items-center gap-8'>
						<div className='lg:max-w-[450px]'>
							<img src={CommunityDiscordImg} alt='いつでもZeroPlus' />
						</div>
						<p className='leading-8'>
							いつでもZeroPlusとは、生徒さん主体のコミュニティです。生徒さん同士でコミュニケーションが取れるDiscordアプリによって、クラスを超えた生徒さん同士での繋がりが生まれています。
						</p>
					</div>
				</div>
			</div>

			<div className='grid grid-cols-2 grid-rows-2 gap-8 sm:gap-4 mb-20'>
				<div className='px-20 py-10 lg:px-2 lg:py-6 bg-white rounded-lg'>
					<h4 className='flex text-lg font-bold mb-6'>
						<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#5AC5ED' className='w-6 h-6 mr-2'>
							<path
								fillRule='evenodd'
								d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z'
								clipRule='evenodd'
							/>
						</svg>
						<span>協力した学習</span>
					</h4>
					<p className='leading-8'>参考サイトを共有しあったり、生徒同士での勉強会が行えます。</p>
				</div>

				<div className='px-20 py-10 lg:px-2 lg:py-6 bg-white rounded-lg'>
					<h4 className='flex text-lg font-bold mb-6'>
						<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#5AC5ED' className='w-6 h-6 mr-2'>
							<path
								fillRule='evenodd'
								d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z'
								clipRule='evenodd'
							/>
						</svg>
						<span>仲間とおしゃべり</span>
					</h4>
					<p className='leading-8'>ゆっくり会話をしたり、案件の相談などができます。</p>
				</div>

				<div className='px-20 py-10 lg:px-2 lg:py-6 bg-white rounded-lg'>
					<h4 className='flex text-lg font-bold mb-6'>
						<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#5AC5ED' className='w-6 h-6 mr-2'>
							<path
								fillRule='evenodd'
								d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z'
								clipRule='evenodd'
							/>
						</svg>
						<span>イベント開催</span>
					</h4>
					<p className='leading-8'>集中して勉強ができます。仲間と高め合いましょう！</p>
				</div>

				<div className='px-20 py-10 lg:px-2 lg:py-6 bg-white rounded-lg'>
					<h4 className='flex text-lg font-bold mb-6'>
						<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#5AC5ED' className='w-6 h-6 mr-2'>
							<path
								fillRule='evenodd'
								d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z'
								clipRule='evenodd'
							/>
						</svg>
						<span>もくもく作業</span>
					</h4>
					<p className='leading-8'>生徒主催でのイベントなども、こちらで行うことができます。</p>
				</div>
			</div>

			<div className='py-10 bg-white rounded-lg text-center'>
				<div className='w-full max-w-[672px] m-auto px-3'>
					<h3 className='text-2xl font-bold mb-8'>いつでもZeroPlusに参加しよう！</h3>
					<p className='leading-8 mb-10'>
						こちらのDiscordから、いつでもZeroPlusに参加をしましょう！
						<br />
						仲間たちが待っています！
					</p>
					{/* TODO: Discordへのリンクに変更する  */}
					<Button title='Discordへ' to='https://discord.com/invite/pcratKmNQK' isExternal />
				</div>
			</div>
		</div>
	);
}

export default Community;
